var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "newdetail-container position-relative" },
    [
      _c("afterSales", { ref: "afterSales" }),
      _vm.isShow
        ? _c(
            "el-form",
            {
              ref: "checkForm",
              attrs: { model: _vm.checkForm, "label-width": "125px" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex a-center mb-15" },
                [
                  _c("div", { staticClass: "responsibilityType" }, [
                    _vm._v("责任判定:"),
                  ]),
                  [
                    _c(
                      "div",
                      {
                        staticClass: "checkNewBtn",
                        class:
                          _vm.checkForm["status"] == _vm.AUDIT_STATUS.fail.value
                            ? "checkNewBtn-active"
                            : "",
                        on: {
                          click: function ($event) {
                            return _vm.tabClick(_vm.AUDIT_STATUS.fail.value)
                          },
                        },
                      },
                      [_vm._v(" 用户责任，驳回 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "checkNewBtn",
                        class:
                          _vm.checkForm["status"] ==
                          _vm.AUDIT_STATUS.success.value
                            ? "checkNewBtn-active"
                            : "",
                        on: {
                          click: function ($event) {
                            return _vm.tabClick(_vm.AUDIT_STATUS.success.value)
                          },
                        },
                      },
                      [_vm._v(" 三方责任，退款 ")]
                    ),
                  ],
                ],
                2
              ),
              _vm.checkForm["status"] == _vm.AUDIT_STATUS.success.value
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "赔付件数：", prop: "product_num" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "textWrap",
                            attrs: {
                              oninput: "value = value.match(/\\d+/)?.[0] || ''",
                            },
                            model: {
                              value: _vm.checkForm.product_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.checkForm, "product_num", $$v)
                              },
                              expression: "checkForm.product_num",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: { color: "#000000" },
                                attrs: { slot: "suffix" },
                                slot: "suffix",
                              },
                              [_vm._v("件")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户退款：" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "textWrap specialWrap",
                            staticStyle: { color: "red" },
                            attrs: {
                              oninput:
                                "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                            },
                            on: { blur: _vm.blurAmount },
                            model: {
                              value: _vm.checkForm.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.checkForm, "amount", $$v)
                              },
                              expression: "checkForm.amount",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { color: "#000000" },
                                attrs: { slot: "suffix" },
                                slot: "suffix",
                              },
                              [_vm._v("元")]
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "tipsWrap" }, [
                          _vm._v(
                            " (用户退款=供应商承担金额+城市仓承担金额+装卸队承担金额) "
                          ),
                        ]),
                        _vm.couponText
                          ? _c("div", { staticStyle: { color: "#fc2e02" } }, [
                              _c("i", {
                                staticClass: "el-icon-warning",
                                attrs: { slot: "default" },
                                slot: "default",
                              }),
                              _vm._v(" " + _vm._s(_vm.couponText) + " "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "供应商承担金额：",
                          prop: "supplier_amount",
                        },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "textWrap",
                            attrs: {
                              oninput:
                                "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                            },
                            model: {
                              value: _vm.checkForm.supplier_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.checkForm, "supplier_amount", $$v)
                              },
                              expression: "checkForm.supplier_amount",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { color: "#000000" },
                                attrs: { slot: "suffix" },
                                slot: "suffix",
                              },
                              [_vm._v("元")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "城市仓承担金额：",
                          prop: "logistics_amount",
                        },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "textWrap",
                            attrs: {
                              oninput:
                                "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                            },
                            model: {
                              value: _vm.checkForm.logistics_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.checkForm, "logistics_amount", $$v)
                              },
                              expression: "checkForm.logistics_amount",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { color: "#000000" },
                                attrs: { slot: "suffix" },
                                slot: "suffix",
                              },
                              [_vm._v("元")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "装卸队承担金额：",
                          prop: "fleet_amount",
                        },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "textWrap",
                            attrs: {
                              oninput:
                                "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                            },
                            model: {
                              value: _vm.checkForm.fleet_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.checkForm, "fleet_amount", $$v)
                              },
                              expression: "checkForm.fleet_amount",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { color: "#000000" },
                                attrs: { slot: "suffix" },
                                slot: "suffix",
                              },
                              [_vm._v("元")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "供应商加扣款：",
                          prop: "supplier_fine",
                        },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "textWrap",
                            attrs: {
                              oninput:
                                "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                            },
                            model: {
                              value: _vm.checkForm.supplier_fine,
                              callback: function ($$v) {
                                _vm.$set(_vm.checkForm, "supplier_fine", $$v)
                              },
                              expression: "checkForm.supplier_fine",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { color: "#000000" },
                                attrs: { slot: "suffix" },
                                slot: "suffix",
                              },
                              [_vm._v("元")]
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "tipsWrap" }, [
                          _vm._v(" (额外的罚款) "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "城市仓加扣款：",
                          prop: "logistics_fine",
                        },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "textWrap",
                            attrs: {
                              oninput:
                                "value= value.match(/\\d+(\\.\\d{0,2})?/) ? value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                            },
                            model: {
                              value: _vm.checkForm.logistics_fine,
                              callback: function ($$v) {
                                _vm.$set(_vm.checkForm, "logistics_fine", $$v)
                              },
                              expression: "checkForm.logistics_fine",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { color: "#000000" },
                                attrs: { slot: "suffix" },
                                slot: "suffix",
                              },
                              [_vm._v("元")]
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "tipsWrap" }, [
                          _vm._v(" (额外的罚款) "),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.checkForm["status"]
                ? _c(
                    "el-form-item",
                    { attrs: { label: "备注：", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticClass: "textareaWrap",
                        attrs: { type: "textarea", autosize: "" },
                        model: {
                          value: _vm.checkForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.checkForm, "remark", $$v)
                          },
                          expression: "checkForm.remark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "fy1 fy2",
          staticStyle: { position: "fixed", bottom: "0", right: "0" },
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm.isShow
            ? _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.sureConfirm } },
                [_vm._v("确定")]
              )
            : _vm._e(),
          _vm.isShow
            ? _c(
                "span",
                { staticStyle: { color: "#e66f34", "margin-left": "10px" } },
                [_vm._v("注：请谨慎填写报损金额，确认后将直接退款给客户！！！")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }