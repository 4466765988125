<template>
  <div class="newdetail-container position-relative">
 <afterSales ref="afterSales"></afterSales>
    <el-form
      :model="checkForm"
      ref="checkForm"
      label-width="125px"
      v-if="isShow"
    >
      <div class="d-flex a-center mb-15">
        <div class="responsibilityType">责任判定:</div>
        <template>
          <div
            class="checkNewBtn"
            @click="tabClick(AUDIT_STATUS.fail.value)"
            :class="
              checkForm['status'] == AUDIT_STATUS.fail.value
                ? 'checkNewBtn-active'
                : ''
            "
          >
            用户责任，驳回
          </div>
          <div
            class="checkNewBtn"
            @click="tabClick(AUDIT_STATUS.success.value)"
            :class="
              checkForm['status'] == AUDIT_STATUS.success.value
                ? 'checkNewBtn-active'
                : ''
            "
          >
            三方责任，退款
          </div>
        </template>
      </div>
      <template v-if="checkForm['status'] == AUDIT_STATUS.success.value">
        <!-- 赔付件数，0322 start -->
        <el-form-item label="赔付件数：" prop="product_num">
          <!-- value => (checkForm.product_num = utilsTool.filterUnNumber(value)) -->
          <el-input
            v-model="checkForm.product_num"
            oninput="value = value.match(/\d+/)?.[0] || ''"
            class="textWrap"
          >
            <span slot="suffix" style="color: #000000">件</span>
          </el-input>
        </el-form-item>
        <!-- 赔付件数，0322 end -->
        <el-form-item label="用户退款：">
          <el-input
            class="textWrap specialWrap"
            v-model="checkForm.amount"
            style="color: red"
            oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            @blur="blurAmount"
          >
            <div slot="suffix" style="color: #000000">元</div>
          </el-input>
          <span class="tipsWrap">
            (用户退款=供应商承担金额+城市仓承担金额+装卸队承担金额)
          </span>
          <!-- 使用优惠券退款金额提示 start -->
          <div style="color: #fc2e02" v-if="couponText">
            <i slot="default" class="el-icon-warning"></i>
            {{ couponText }}
          </div>
          <!-- 使用优惠券退款金额提示 end -->
        </el-form-item>
        <el-form-item label="供应商承担金额：" prop="supplier_amount">
          <el-input
            v-model="checkForm.supplier_amount"
            oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            class="textWrap"
          >
            <div slot="suffix" style="color: #000000">元</div>
          </el-input>
        </el-form-item>
        <el-form-item label="城市仓承担金额：" prop="logistics_amount">
          <el-input
            v-model="checkForm.logistics_amount"
            oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            class="textWrap"
          >
            <div slot="suffix" style="color: #000000">元</div>
          </el-input>
        </el-form-item>
        <el-form-item label="装卸队承担金额：" prop="fleet_amount">
          <el-input
            v-model="checkForm.fleet_amount"
            oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            class="textWrap"
          >
            <div slot="suffix" style="color: #000000">元</div>
          </el-input>
        </el-form-item>
        <el-form-item label="供应商加扣款：" prop="supplier_fine">
          <el-input
            v-model="checkForm.supplier_fine"
            oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            class="textWrap"
          >
            <div slot="suffix" style="color: #000000">元</div>
          </el-input>
          <span class="tipsWrap"> (额外的罚款) </span>
        </el-form-item>
        <el-form-item label="城市仓加扣款：" prop="logistics_fine">
          <el-input
            v-model="checkForm.logistics_fine"
            oninput="value= value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            class="textWrap"
          >
            <div slot="suffix" style="color: #000000">元</div>
          </el-input>
          <span class="tipsWrap"> (额外的罚款) </span>
        </el-form-item>
      </template>
      <el-form-item label="备注：" prop="remark" v-if="checkForm['status']">
        <el-input
          class="textareaWrap"
          type="textarea"
          autosize
          v-model="checkForm.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="fy1 fy2" style="position: fixed; bottom: 0; right: 0">
      <el-button @click="$router.back()">返回</el-button>
      <el-button type="success" @click="sureConfirm" v-if="isShow"
        >确定</el-button
      >
      <span style="color: #e66f34; margin-left: 10px" v-if="isShow"
        >注：请谨慎填写报损金额，确认后将直接退款给客户！！！</span
      >
    </div>

  </div>
</template>

<script>
// import { BASE } from "@/api";
import { Loading } from "element-ui";
import utilsTool from "@/utils/tools/tool";
import { AUDIT_STATUS } from "@/views/general/utils/enum/index";
import { REVIEW_STATUS_ENUM, AFTER_SHOW_TYPE_ENUM } from "./utils/enum";
import { postActualRefund } from "@/api/generalControl/coupon";
import afterSales from "./Aftersales/afterSales.vue";
import { postAfterSaleStatistics } from "@/api/generalControl/index.js";

export default {
  components: {
    afterSales
  },
  data() {
    return {
      AFTER_SHOW_TYPE_ENUM,
      afterLoading: true,
      afterTableData: [],
      REVIEW_STATUS_ENUM,
      utilsTool,
      AUDIT_STATUS,
      aftertype: {
        10: "运损——外包装损坏，如压坏、挤压、漏洞、变形等",
        20: "缺货——到货件数小于购买件数",
        30: "质量问题——坏果、标品漏坏、商品变质、涨袋、破损、过期等的问题",
        40: "货不对板——到货商品与商品描述不符",
        50: "缺斤少两",
        60: "差价",
      },
      merchantList: {},
      step: "",
      savebtn: false,
      id: "",
      goodsData: [
        //商品信息
      ],
      loading: true,
      initCount: "", // 初始详情接口用户退款
      checkForm: {
        after_id: "",
        status: "",
        amount: "",
        supplier_amount: "",
        supplier_fine: "",
        logistics_amount: "",
        logistics_fine: "",
        fleet_amount: "",
        remark: "",
        product_num: "",
      },
      isShow: false, // 判断是否显示责任判定
      couponText: "", // 优惠券退款提示
    };
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.checkForm.after_id = this.id;
      this.state = this.$route.query.state;
      this.getDetail();
      this.getStep();
      this.postAjaxAfterSaleStatistics();
    }
  },
  methods: {
    // 修改毛重 净重 记录
    onHandleWeight(data) {
      this.$refs.refWeightLog.onInitData({ ...data, isCount: true });
    },
    // 售后率列表展示
    async postAjaxAfterSaleStatistics() {
      try {
        const res = await postAfterSaleStatistics({
          after_id: this.id,
        });
        this.afterTableData = res?.data || [];
        this.$refs.afterSales.hqinfoafterTableData(this.afterTableData)
      } catch (error) {
        console.log(error, "postAfterSaleStatistics");
      } finally {
        this.afterLoading = false;
      }
    },
    // 用户退款 失去焦点
    blurAmount(e) {
      console.log(e, this.checkForm.amount, "e==");
      this.couponText = "";
      if (this.checkForm.amount) {
        this.postAjaxActualRefund();
      }
    },
    // 获取使用优惠券提示
    async postAjaxActualRefund() {
      try {
        const res = await postActualRefund({
          order_detail_id: this.merchantList.aftersale_detail.order_detail_id,
          order_id: this.merchantList.aftersale_detail.order_id,
          refund_amt: this.checkForm.amount,
        });
        this.couponText = res.data || "";
      } catch (error) {
        this.couponText = "";
        console.log(error, "e---");
      }
    },
    tabClick(status) {
      this.checkForm.status = status;
      this.checkForm.amount = this.initCount;
      // 切换责任类型 清空
      this.checkForm.supplier_amount = "";
      this.checkForm.supplier_fine = "";
      this.checkForm.logistics_amount = "";
      this.checkForm.logistics_fine = "";
      this.checkForm.fleet_amount = "";
      this.checkForm.remark = "";
    },
    sureConfirm() {
      console.log(this.checkForm);
      if (!this.checkForm.status) {
        this.tool.message("请选择审核结果", "error");
        return;
      }
      // 计算供应商承担金额 城市仓承担金额 装卸队承担金额
      let tmpCount =
        (Number(this.checkForm.supplier_amount) * 100 +
          Number(this.checkForm.logistics_amount) * 100 +
          Number(this.checkForm.fleet_amount) * 100) /
        100;
      tmpCount = tmpCount.toFixed(2);
      // 增加用户退款金额判断
      if (
        this.checkForm.status == AUDIT_STATUS.success.value &&
        Number(this.checkForm.amount) != tmpCount
      ) {
        this.tool.message(
          "用户退款要等于供应商承担金额,城市仓承担金额,装卸队承担金额之和！",
          "error"
        );
        return;
      }
      this.$confirm(
        `${
          this.checkForm.status == AUDIT_STATUS.fail.value
            ? "您将驳回 【" +
              this.merchantList.after.member.fullname +
              "】的售后申请，请确认您的操作，确认后将不能修改！！！"
            : "您将通过 【" +
              this.merchantList.after.member.fullname +
              "】的售后申请，请确认您的操作，确认后将不能修改！！"
        }`,
        // "确定审核吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          let load = Loading.service({ fullscreen: false });
          this.tool.debounce(() => {
            let form = this.tool.DeepCopy({}, this.checkForm);
            // form.status = form.status == 50 ? 30 : form.status;
            this.$api.general
              .aftersalenewHandle(form)
              .then((res) => {
                load.close();
                this.tool.message("审核成功", "success");
                setTimeout(() => {
                  this.$router.back();
                }, 800);
              })
              .catch((error) => {
                load.close();
              });
          });
        })
        .catch(() => {});
    },
    // 获取详情
    getDetail() {
      this.$api.general
        .aftersalenewDetail({ after_id: this.id })
        .then((res) => {
          this.goodsData = [res.data.aftersale_detail];
          const { aftersale_cond, as_cond } = res.data.aftersale_detail.product;
          let asCond = "";
          if (aftersale_cond?.length) {
            aftersale_cond.map((item) => {
              asCond += `${item.name}；`;
            });
          }
          this.merchantList = res.data;
          this.merchantList.aftersale_detail.product.as_cond =
            asCond || as_cond;
          this.goodsData = [res.data.aftersale_detail];
          this.loading = false;
          this.checkForm.amount = this.tool.toDecimal2(
            (this.merchantList.after.refund_amt * 10000) / 10000 / 100
          );
          this.initCount = this.tool.toDecimal2(
            (this.merchantList.after.refund_amt * 10000) / 10000 / 100
          );
          // if (this.merchantList.aftersale_detail.reason_type == 10) {
          //   this.checkForm.status = 40;
          // }
          this.checkForm.product_num =
            this.merchantList?.aftersale_detail?.product_num;
          this.postAjaxActualRefund();
          this.$refs.afterSales.hqinfogoodsData(this.goodsData)
          this.$refs.afterSales.hqinfomerchantList(this.merchantList)
        });
    },
    // 获取流程
    getStep() {
      this.$api.general.aftersaleStep({ after_id: this.id }).then((res) => {
        this.step = res.data;
        this.$refs.afterSales.hqinfostep(this.step)

        let lastObj = this.step[res.data.length - 1]; // 判断流程最后一步
        // 处理类型 20 审核 &&处理人类型 40 总控 && 状态 20 待审批 才显示责任判定
        if (
          lastObj.action_type == AUDIT_STATUS.pending.value &&
          lastObj.handle_type == AUDIT_STATUS.fail.value &&
          lastObj.status == AUDIT_STATUS.pending.value
        ) {
          this.isShow = true;
        }
      });
    },
  },
};
</script>

<style lang="less">
.newdetail-container {
  .kv {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  .mb {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .form-box {
    .el-form-item {
      margin-bottom: 0;
    }
  }

  .video {
    width: 230px;
    height: 230px;
  }

  .after-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;

    img {
      object-fit: fill;
    }
  }
  .after-image:last-of-type {
    margin-right: 0;
  }



  .activeimg {
    width: 16px;
    height: 16px;
  }
  .textWrap {
    width: 275px !important;
  }
  .specialWrap {
    .el-input__inner {
      color: #f93a16;
    }
  }
  .textareaWrap {
    .el-textarea__inner {
      height: 87px !important;
    }
  }
  .responsibilityType {
    width: 80px;
    font-size: 16px;
    font-weight: 500;
    color: #fb8638;
  }
  .tipsWrap {
    font-weight: 500;
    color: #38b2fb;
    margin-left: 8px;
  }

  .checkNewBtn {
    cursor: pointer;
    // width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    margin-right: 10px;
    padding: 0px 7px;
    border: 1px solid #333333;
  }

  .checkNewBtn:last-of-type {
    margin-right: 0;
  }

  .checkNewBtn-active {
    background-color: #fb8638;
    border: 1px solid #fb8638;
    color: #ffffff;
  }

  .font {
    font-size: 12px;
  }

  .fy2 {
    left: 250px;
  }

  .pt {
    padding-top: 5px;
  }

  .py {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pb {
    padding-bottom: 5px;
  }

  .border-b {
    border-bottom: 1px solid #ebeef5;
  }

  .video {
    width: 230px;
  }

  .el-input.is-disabled .el-input__inner,
  .el-textarea.is-disabled .el-textarea__inner {
    color: #333333;
  }

  .remark-wrapper {
    padding: 20px 60px;
    border: 1px solid #dbe0e4;
  }

  .input-width {
    width: 200px;
  }

  .sale-font {
    font-size: 15px;
  }

  padding: 22px 25px 60px;
  background-color: #ffffff;
  box-sizing: border-box;
  min-height: calc(100% - 20px);

  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  // .auto-table-flex .el-table .el-table__footer-wrapper .cell {
  //   color: #13AE67;
  //   font-size: 20px;
  // }
}
</style>
