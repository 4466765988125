import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-运营中心-优惠券列表
 * @param {*} params
 * @returns
 */
export function getCouponList(params = {}) {
  return axios.get(`${BASE_URL}/coupon/list`, { params });
}

/**
 * 总控-运营中心-优惠券禁用启用接口
 * @param {*} params
 * @returns
 */
export function postCouponStatus(params = {}) {
  return axios.post(`${BASE_URL}/coupon/status`, params);
}

/**
 * 总控-运营中心-补发优惠券-搜索用户
 * @param {*} params
 * @returns
 */
export function getCouponMemberList(params = {}) {
  return axios.get(`${BASE_URL}/coupon/member_list`, { params });
}

/**
 * 总控-运营中心-补发优惠券
 * @param {*} params
 * @returns
 */
export function postSendCoupon(params = {}) {
  return axios.post(`${BASE_URL}/coupon/send_coupon`, params);
}

/**
 * 总控-运营中心-优惠券发放列表
 * @param {*} params
 * @returns
 */
export function getCouponRecord(params = {}) {
  return axios.get(`${BASE_URL}/coupon/coupon_record`, { params });
}

/**
 * 总控-平台介入实际退款使用优惠券提示
 * @param {*} params
 * @returns
 */
export function postActualRefund(params = {}) {
  return axios.post(`${BASE_URL}/aftersale/actual/refund`, params);
}

/**
 * 总控-运营中心-优惠券发放列表-合计
 * @param {*} params
 * @returns
 */
export function getCouponRecordCount(params = {}) {
  return axios.get(`${BASE_URL}/coupon/deduction/count`, { params });
}
/**
 * 总控-运营中心-优惠券发放列表-群发
 * @param {*} params
 * @returns
 */
export function send_coupon_batch(params = {}) {
  return axios.post(`${BASE_URL}/coupon/send_coupon_batch`,  params );
}
